import { useState, useEffect } from "react";
import React from "react";
import {
    Row,
    Col,
    Form,
    Input,
    InputNumber,
    Radio,
    Select,
    Switch,
    Button,
    message,
    AutoComplete,
} from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import { getCompanyDetails } from "../../../../Adapters/companyCalls.js";
import styles from "../../AdminDashboard.module.css";
import { FactoryData } from "../../../../RecoilState/FactoryData";
import { useRecoilState } from "recoil";
import { Upload } from "antd";
import { common_axios } from "../../../../utils/AxiosSettings";
import { userState } from "../../../../RecoilState/userState";
import { useNavigate } from "react-router";
import { factoryState } from "../../../../RecoilState/FactoryState";
import { InboxOutlined } from "@ant-design/icons";
import { useFetchFactories } from "../../UserDashboard/UserAction/UserHooks/useFetchFactories";
import useEventMetricsUpdater from "../../../../hooks/useEventMetricsUpdater";
import useFetchRegion from "../../RegionDashboard/RegionHooks/FetchRegionHook";
import { debounce } from "lodash";
import AddEditDropdown from "../../../Utils/AddEditDropdown";
import {
    addDivision,
    editDivision,
    getDivisionDetails,
} from "../../../../Adapters/DivisionCalls";
import { fetchTimeZones } from "../../../../Adapters/FactoryAndCompanyCalls.js";
import {
    extractTimeZone,
    handleTimeZoneSearch,
} from "../../../../utils/TimeZoneFunctions/TimeZoneFunctions.js";

const { Dragger } = Upload;

const { Option } = Select;

const CreateFactory = ({ regionData }) => {
    const [file, setFile] = useState(null);
    const [FactoryDataForm] = Form.useForm();
    const [user, setUser] = useRecoilState(userState);
    let navigate = useNavigate();
    const [factorySelected, setFactorySelected] = useRecoilState(factoryState);
    const [company, setCompany] = useState(null);
    const [FactoryList, setFactoryList] = useRecoilState(FactoryData);

    const { fetchFactoryData, isLoading, factories } = useFetchFactories();
    const { fetchRegionData } = useFetchRegion();
    const [fileError, setFileError] = useState(null);
    const { updateEventMetrics } = useEventMetricsUpdater();
    const [timeZoneOptions, setTimeZoneOptions] = useState([]);
    const [timeZones, setTimeZones] = useState([]);
    useEffect(() => {
        FactoryDataForm.setFieldsValue({
            requiredAwarenessProgram: 4,
        });

        if (
            FactoryList?.length === 0 ||
            !factorySelected ||
            factorySelected === undefined
        ) {
            getCompanyDetails(user?.company_fk)
                .then((res) => {
                    const company = res.data.Legalcompanyname;
                    const id = res.data.id;
                    setFactorySelected((prev) => ({
                        ...prev,
                        Company: company,
                        Company_id: id,
                    }));
                    FactoryDataForm.setFieldsValue({
                        Company: company,
                    });
                })

                .catch((error) => {
                    message.error("Failed to fetch company details");
                });
        }
    }, [user, FactoryList?.length]);
    const [divisionList, setDivisionList] = useState([]);
    const [handleDropdown, setHandleDropdown] = useState({
        open: false,
        edit: false,
        editId: null,
        disableInput: true,
        refreshRegion: true,
    });

    const [fieldValue, setFieldValue] = useState({
        id: null,
        fieldrename: "",
        addField: "",
        newFieldName: "",
    });
    const fetchDivisionData = () => {
        getDivisionDetails(user?.company_fk).then((res) => {
            setDivisionList(res.data.message_body.Division);
        });
    };
    useEffect(() => {
        fetchDivisionData();
        const fetchData = async () => {
            const data = await fetchTimeZones();
            setTimeZones(data);
        };

        fetchData();
    }, [user]);
    const handleSearch = (value) => {
        handleTimeZoneSearch(timeZones, value, setTimeZoneOptions);
    };
    const RenameDivision = (id, name) => {
        editDivision(id, fieldValue.fieldrename)
            .then((res) => {
                message.success(res.data.message);
                fetchDivisionData();
            })
            .catch((err) => {
                if (err.response.status === 400) {
                    message.error(err.response.data.errorMessage);
                }
            });
    };

    async function handleUserDataFormSubmit(eventName, actionType) {
        try {
            if (!fieldValue.id && fieldValue.newFieldName) {
                await addDivision(fieldValue.newFieldName)
                    .then((res) => {
                        FactoryDataForm.setFieldValue(
                            "division",
                            res.data.message_body.id
                        );
                        message.success(res.data.message);
                    })
                    .catch((error) => {
                        message.error(error?.response?.data?.errorMessage);
                        return;
                    });
            } else {
                FactoryDataForm.setFieldValue("division", fieldValue.id);
            }
        } catch {
            return;
        }
        // Check if a file is selected and its extension is csv
        if (!file || (file && file.type === "text/csv")) {
            const submitData = {
                Company:
                    user.role === "REGIONAL_ADMIN"
                        ? FactoryList[0]?.Company_id
                        : factorySelected?.Company_id,
                Code: FactoryDataForm.getFieldValue("Code"),
                Location: FactoryDataForm.getFieldValue("Location"),
                Number: `91${FactoryDataForm.getFieldValue("Number")}`,
                division: FactoryDataForm.getFieldValue("division"),
                region:
                    user.role === "REGIONAL_ADMIN"
                        ? user.region_fk
                        : FactoryDataForm.getFieldValue("Region"),
                Factory_timezone: extractTimeZone(
                    FactoryDataForm.getFieldValue("Factory_timezone")
                ),
            };
            common_axios
                .post(
                    `${process.env.REACT_APP_BASE_URL_API}/api/accounts/factories/`,
                    submitData
                )
                .then((response) => {
                    if (response.status === 200) {
                        message.success(response.data.message);

                        if (file) {
                            const formData = new FormData();
                            formData.append("csv_file", file);
                            formData.append(
                                "Company",
                                factorySelected.Company_id
                            );
                            formData.append("Factory", response.data.id);

                            common_axios
                                .post(
                                    `${process.env.REACT_APP_BASE_URL_API}/api/accounts/complainers/`,
                                    formData
                                )
                                .then((response2) => {
                                    message.success(response2?.data?.message);
                                })
                                .catch((error) => {
                                    message.error(
                                        error?.response?.data?.errorMessage
                                    );
                                });
                        }

                        navigate("/AdminTableView?key=2");
                        fetchFactoryData();
                        fetchRegionData();
                        updateEventMetrics(eventName, actionType);
                    } else {
                        message.error(
                            "An error occurred while processing your request."
                        );
                    }
                })
                .catch((error) => {
                    if (
                        error?.response?.status >= 400 &&
                        error?.response?.status < 500
                    ) {
                        const errorMessage = error?.response?.data?.Code;
                        message.error(
                            `Error: ${
                                errorMessage
                                    ? errorMessage
                                    : error?.response?.data?.errorMessage
                            }`
                        );
                        if (
                            errorMessage ===
                            "factory with this Code already exists."
                        ) {
                            const errormsg = [
                                "Factory with this number already exist, please enter a different Factory Number",
                            ];
                            FactoryDataForm.setFields([
                                {
                                    name: "Code",
                                    errors: errormsg,
                                },
                            ]);
                        }
                    } else {
                        message.error(
                            "An error occurred while processing your request."
                        );
                        message.error(error?.response?.data);
                    }
                });
        } else {
            message.error("Please upload Master Data File in CSV format");
        }
    }

    const onFileChange = (info) => {
        const fileList = info.fileList;
        if (fileList.length > 0) {
            const file = fileList[0].originFileObj;
            setFile(file);
        } else {
            setFile(null);
            setFileError(null); // Reset the error when no file is selected
        }
    };
    const initialValues = {
        Region: user.role === "REGIONAL_ADMIN" ? user.region : null,
    };
    const debouncehandler = debounce(async (eventName, actionType) => {
        handleUserDataFormSubmit(eventName, actionType);
    }, 500);
    return (
        <>
            {initialValues.Region !== "" && (
                <div>
                    <Form
                        name="Factory Detail Form"
                        form={FactoryDataForm}
                        initialValues={initialValues}
                        onFinish={() =>
                            debouncehandler(
                                "createFatory",
                                "Add Factory - Create Factory "
                            )
                        }>
                        <Row gutter={16} style={{ width: "100%" }}>
                            <Col style={{ width: "50%" }}>
                                <Form.Item
                                    label="Company"
                                    name="Company"
                                    value={
                                        user.role === "REGIONAL_ADMIN"
                                            ? FactoryList[0]?.Company
                                            : factorySelected?.Company
                                    }>
                                    <Input
                                        defaultValue={
                                            user.role === "REGIONAL_ADMIN"
                                                ? FactoryList[0]?.Company
                                                : factorySelected?.Company
                                        }
                                        disabled
                                    />
                                </Form.Item>
                                <Form.Item
                                    label="Factory Number"
                                    name="Code"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                "Please enter the Factory Number",
                                        },
                                        {
                                            pattern: /^[a-zA-Z0-9]+$/,
                                            message:
                                                "Factory Number must be alphanumeric",
                                        },
                                    ]}>
                                    <Input maxLength={10} />
                                </Form.Item>
                                <Form.Item
                                    label="Region"
                                    name="Region"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please select a Region",
                                        },
                                    ]}>
                                    <Select
                                        defaultValue={
                                            user.role === "REGIONAL_ADMIN"
                                                ? user.region
                                                : ""
                                        }
                                        disabled={
                                            user.role === "REGIONAL_ADMIN"
                                        }>
                                        {regionData.map((region) => (
                                            <Option
                                                key={region.id}
                                                value={region.id}>
                                                {region.Name}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    label="Division"
                                    name="Division"
                                    initialValue={fieldValue.id}
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please select a Division",
                                            transform: (value) => {
                                                if (!fieldValue.id) {
                                                    fieldValue.newFieldName &&
                                                        value.set(
                                                            fieldValue.newFieldName
                                                        );
                                                } else {
                                                    value.set(fieldValue.id);
                                                }
                                            },
                                        },
                                    ]}>
                                    <AddEditDropdown
                                        includesPermission={user?.group_permissions?.includes(
                                            "change_factory"
                                        )}
                                        fieldValue={fieldValue}
                                        setFieldValue={setFieldValue}
                                        handleDropdown={handleDropdown}
                                        setHandleDropdown={setHandleDropdown}
                                        fields={divisionList}
                                        RenameField={RenameDivision}
                                        allowSpaces={false}
                                    />
                                </Form.Item>
                            </Col>
                            <Col style={{ width: "50%" }}>
                                <Form.Item
                                    label="Upload Master Data"
                                    name="csv_file"
                                    help={fileError} // Display the error message
                                    validateStatus={fileError ? "error" : ""}>
                                    <Dragger
                                        maxCount={1}
                                        accept=".csv"
                                        beforeUpload={(file) => {
                                            if (file.type !== "text/csv") {
                                                setFileError(
                                                    "Please upload a correct format of Master Data File, required format is CSV"
                                                );
                                                return false;
                                            } else {
                                                setFileError(null); // Reset the error
                                                setFile([file]);
                                                return false; // prevent automatic upload
                                            }
                                        }}
                                        onChange={onFileChange}>
                                        <p className="ant-upload-drag-icon">
                                            <InboxOutlined />
                                        </p>
                                        <p className="ant-upload-text">
                                            Click or drag file to this area to
                                            upload
                                        </p>
                                        <p className="ant-upload-hint">
                                            Support for a single CSV file
                                            upload.
                                        </p>
                                    </Dragger>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={16} style={{ width: "100%" }}>
                            <Col style={{ width: "50%" }}>
                                <Form.Item
                                    label="Location"
                                    name="Location"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                "Please enter the Location",
                                        },
                                    ]}>
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col
                                style={{
                                    width: "50%",
                                }}>
                                <Button
                                    className={styles.outlineButton}
                                    onClick={() => {
                                        const url = `/assets/Doc/MasterDataTemplate.csv`;
                                        const link =
                                            document.createElement("a");
                                        link.href = url;
                                        link.download = "template.csv";
                                        document.body.appendChild(link);
                                        link.click();
                                        document.body.removeChild(link);
                                    }}>
                                    <DownloadOutlined />
                                    Download Sample Template
                                </Button>
                            </Col>
                        </Row>
                        <Row gutter={16} style={{ width: "100%" }}>
                            <Col style={{ width: "50%" }}>
                                <Form.Item
                                    label="Time Zone"
                                    name="Factory_timezone"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                "Please select a Time zone",
                                        },
                                    ]}>
                                    <AutoComplete
                                        disabled={!timeZones.length}
                                        placeholder="Enter Time zone"
                                        options={timeZoneOptions}
                                        onSearch={handleSearch}
                                        dropdownRender={(menu) => (
                                            <div className="autocomplete-dropdown">
                                                {menu}
                                            </div>
                                        )}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16} style={{ width: "100%" }}>
                            <Col style={{ width: "50%" }}>
                                <Form.Item
                                    label="Inache Number"
                                    name="Number"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                "Please select an Inache Number",
                                        },
                                        {
                                            pattern: /^[0-9]+$/,
                                            message:
                                                "Please input numbers only!",
                                        },
                                    ]}>
                                    <Input
                                        maxLength={10}
                                        addonBefore="+91"></Input>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Form.Item>
                            <Row justify="end" style={{ padding: "0 1rem" }}>
                                <Col>
                                    <button
                                        className="primaryButton"
                                        htmlType="submit">
                                        Create Factory
                                    </button>
                                </Col>
                            </Row>
                        </Form.Item>
                    </Form>
                </div>
            )}
        </>
    );
};

export default CreateFactory;
