import { useState, useEffect, useRef } from "react";
import React from "react";
import {
    Row,
    Col,
    Form,
    Input,
    Select,
    InputNumber,
    Button,
    Modal,
    message,
    AutoComplete,
} from "antd";
import { FactoryData } from "../../../../RecoilState/FactoryData";
import { useRecoilState } from "recoil";
import { Upload } from "antd";
import { common_axios } from "../../../../utils/AxiosSettings";
import { useNavigate } from "react-router";
import { InboxOutlined, DownloadOutlined } from "@ant-design/icons";
import { factoryState } from "../../../../RecoilState/FactoryState";
import { EditOutlined, EyeOutlined } from "@ant-design/icons";
import {
    fetchTimeZones,
    getAllFactoriesOfCompany,
} from "../../../../Adapters/FactoryAndCompanyCalls";
import { tokenState } from "../../../../RecoilState/tokenState";
import styles from "../../AdminDashboard.module.css";
import { userState } from "../../../../RecoilState/userState";
import useEventMetricsUpdater from "../../../../hooks/useEventMetricsUpdater";
import { useFetchUserData } from "../../UserDashboard/UserAction/UserHooks/useFechUserDataAdmin";
import useFetchRegion from "../../RegionDashboard/RegionHooks/FetchRegionHook";
import AddEditDropdown from "../../../Utils/AddEditDropdown";
import {
    addDivision,
    editDivision,
    getDivisionDetails,
} from "../../../../Adapters/DivisionCalls";
import {
    extractTimeZone,
    getFullTimeZoneFormat,
    handleTimeZoneSearch,
} from "../../../../utils/TimeZoneFunctions/TimeZoneFunctions";

const { Dragger } = Upload;
const { Option } = Select;

const EditFactoryButton = ({ props, showText }) => {
    const [file, setFile] = useState(null);
    const [isFactoryModalVisible, setIsFactoryModalVisible] = useState(false);
    const [FactoryDataForm] = Form.useForm();
    const isFormChanged = useRef(false);
    const [user, setUser] = useRecoilState(userState);
    let navigate = useNavigate();
    const [factorySelected, setFactorySelected] = useRecoilState(factoryState);
    const [factoryList, setFactoryList] = useRecoilState(FactoryData);
    const [token, setToken] = useRecoilState(tokenState);
    const [fileError, setFileError] = useState(null);
    const { updateEventMetrics } = useEventMetricsUpdater();
    const { fetchUserData } = useFetchUserData();
    const { fetchRegionData } = useFetchRegion();
    const [divisionList, setDivisionList] = useState([]);
    const [uploadKey, setUploadKey] = useState(Date.now()); // Unique key for Dragger
    const [timeZoneOptions, setTimeZoneOptions] = useState([]);
    const [timeZones, setTimeZones] = useState([]);
    const [handleDropdown, setHandleDropdown] = useState({
        open: false,
        edit: false,
        editId: null,
        disableInput: true,
        refreshRegion: true,
    });

    const [fieldValue, setFieldValue] = useState({
        id: null,
        fieldrename: "",
        addField: "",
        newFieldName: "",
    });

    const fetchDivisionData = () => {
        getDivisionDetails(props.Company_id).then((res) => {
            setDivisionList(res.data.message_body.Division);
        });
    };

    const RenameDivision = (id, name) => {
        handleFormChange();
        editDivision(id, fieldValue.fieldrename)
            .then((res) => {
                message.success(res.data.message);
                fetchDivisionData();
            })
            .catch((err) => {
                if (err.response.status === 400) {
                    message.error(err.response.data.errorMessage);
                }
            });
    };

    useEffect(() => {
        if (isFactoryModalVisible) {
            fetchDivisionData();
            const fetchData = async () => {
                const data = await fetchTimeZones();
                setTimeZones(data);
            };

            fetchData();
        }
    }, [isFactoryModalVisible]);

    useEffect(() => {
        FactoryDataForm.setFieldsValue({
            Company: props?.Company,
            Code: props?.Code,
            Location: props?.Location,
            division: props?.division_id,
            Number: props?.Number,
            Factory_timezone: getFullTimeZoneFormat(
                timeZones,
                props?.Factory_timezone
            ),
        });
        setFieldValue((prev) => ({
            ...prev,
            id: props?.division_id,
        }));
    }, [props, timeZones]);

    function refreshData() {
        getAllFactoriesOfCompany(
            props.Company_id,
            token.access,
            user.role === "REGIONAL_ADMIN" && "region"
        ).then((res) => {
            setFactoryList(res.data.message_body.Factories);
        });
    }

    const showModal = () => {
        setIsFactoryModalVisible(true);
    };

    const handleDivisionValue = async (value) => {
        try {
            if (!fieldValue.id && fieldValue.newFieldName) {
                handleFormChange();
                await addDivision(fieldValue.newFieldName)
                    .then((res) => {
                        FactoryDataForm.setFieldValue(
                            "division",
                            res.data.message_body.id
                        );
                        setFieldValue((prev) => ({
                            ...prev,
                            fieldrename: "",
                            addField: "",
                            newFieldName: "",
                        }));
                        setHandleDropdown((prev) => ({
                            open: false,
                            edit: false,
                            editId: null,
                            disableInput: true,
                            refreshRegion: true,
                        }));
                        message.success(res.data.message);
                    })
                    .catch((error) => {
                        message.error(error?.response?.data?.errorMessage);
                        return;
                    });
            } else {
                if (props.division_id !== fieldValue.id) {
                    handleFormChange();
                }
                FactoryDataForm.setFieldValue("division", fieldValue.id);
            }
        } catch {
            return;
        }
    };

    const handleOk = async (eventName, actionType) => {
        handleDivisionValue().then(async () => {
            await handleFactoryDataFormSubmit();
            setIsFactoryModalVisible(false);
            refreshFields();
            await fetchUserData();
            await fetchRegionData();
            updateEventMetrics(eventName, actionType);
        });
    };

    const handleCancel = () => {
        setIsFactoryModalVisible(false);
        refreshFields();
        setFile(null);
        setUploadKey(Date.now());
    };

    function refreshFields() {
        FactoryDataForm.setFieldsValue({
            Company: props.Company,
            Code: props.Code,
            Location: props.Location,
            Number: props.Number,
            Region: props.Region,
            Factory_timezone: getFullTimeZoneFormat(
                timeZones,
                props?.Factory_timezone
            ),
        });
    }

    function handleFactoryDataFormSubmit() {
        if (isFormChanged.current) {
            const processWithoutFile = () => {
                const submitData = {
                    Company: props.Company_id,
                    Code: FactoryDataForm.getFieldValue("Code"),
                    Location: FactoryDataForm.getFieldValue("Location"),
                    Number: FactoryDataForm.getFieldValue("Number"),
                    division: FactoryDataForm.getFieldValue("division"),
                    // Region: FactoryDataForm.getFieldValue("Region"), not required for RA and SA
                    requiredAwarenessProgram: FactoryDataForm.getFieldValue(
                        "requiredAwarenessProgram"
                    ),
                    Factory_timezone: extractTimeZone(
                        FactoryDataForm.getFieldValue("Factory_timezone")
                    ),
                };

                common_axios
                    .put(
                        `${process.env.REACT_APP_BASE_URL_API}/api/accounts/factories/?id=${props.id}`,
                        submitData
                    )
                    .then((response) => {
                        if (response.status === 200) {
                            message.success(response.data.message);
                            setIsFactoryModalVisible(false);
                            refreshData();
                        } else {
                            message.error(
                                "An error occurred while processing your request."
                            );
                            refreshData();
                        }
                    })
                    .catch((error) => {
                        message.error(
                            error?.response?.data?.errorMessage ||
                                "An error occurred."
                        );
                        //  refreshData();
                    });
            };

            if (file) {
                if (file.type === "text/csv") {
                    processWithoutFile(); // Process other form data first.

                    const formData = new FormData();
                    formData.append("csv_file", file);
                    formData.append("Company", factorySelected.Company_id);
                    formData.append("Factory", props.id);
                    common_axios
                        .post(
                            `${process.env.REACT_APP_BASE_URL_API}/api/accounts/complainers/`,
                            formData
                        )
                        .then((response2) => {
                            message.success(response2?.data?.message);
                            setIsFactoryModalVisible(false);
                            refreshData();
                        })
                        .catch((error) => {
                            message.error(error?.response?.data?.errorMessage);
                        });
                } else {
                    message.error(
                        "Please upload Master Data File in CSV format"
                    );
                }
            } else {
                processWithoutFile();
            }
        } else {
            message.info("No changes were made, Thank you");
        }
    }

    function handleFormChange() {
        isFormChanged.current = true;
    }

    const onFileChange = (info) => {
        const fileList = info.fileList;
        if (fileList.length > 0) {
            const file = fileList[0].originFileObj;
            setFile(file);
        } else {
            setFile(null);
            setFileError(null); // Reset the error when no file is selected
        }
    };
    const handleSearch = (value) => {
        handleTimeZoneSearch(timeZones, value, setTimeZoneOptions);
    };

    return (
        <>
            <Button
                icon={
                    showText ? (
                        <div
                            style={{
                                color: "white",
                                padding: "13px 10px",
                            }}>
                            Edit Details
                        </div>
                    ) : user.role == "LEAD_SUPERVISOR" ? (
                        <EyeOutlined />
                    ) : (
                        <EditOutlined />
                    )
                }
                type="text"
                onClick={showModal}></Button>
            <Modal
                title="Edit Factory"
                open={isFactoryModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={[
                    user?.group_permissions?.includes("change_factory") && (
                        <Button
                            key="cancel"
                            onClick={handleCancel}
                            className={styles.outlineButton}>
                            Cancel
                        </Button>
                    ),
                    user?.group_permissions?.includes("change_factory") && (
                        <Button
                            key="ok"
                            onClick={() =>
                                handleOk(
                                    "editFactory",
                                    "Edit Factory- Save Changes"
                                )
                            }
                            className={styles.solidButton}>
                            Save Change
                        </Button>
                    ),
                ]}
                width={1400}
                closeIcon>
                <div>
                    <Form
                        name="User Detail Form"
                        form={FactoryDataForm}
                        onValuesChange={handleFormChange}
                        onFinish={handleFactoryDataFormSubmit}
                        initialValues={props}>
                        <Row gutter={16} style={{ width: "100%" }}>
                            <Col style={{ width: "50%" }}>
                                <Form.Item label="Company" name="Company">
                                    <Input disabled />
                                </Form.Item>
                                <Form.Item
                                    label="Factory Number"
                                    name="Code"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                "Please enter the Factory Number",
                                        },
                                        {
                                            pattern: /^[a-zA-Z0-9]+$/,
                                            message:
                                                "Factory Number must be alphanumeric",
                                        },
                                    ]}>
                                    <Input
                                        disabled={
                                            ![
                                                "SUPER_ADMIN",
                                                "REGIONAL_ADMIN",
                                            ].includes(user.role)
                                        }
                                        maxLength={10}></Input>
                                </Form.Item>
                                {/* removing this code block in edit factory as Region field not required for any user */}
                                {/* {user.role === "SUPER_ADMIN" ||
                                user.role === "REGIONAL_ADMIN" ? null : (
                                    <Form.Item
                                        label="Region"
                                        name="Region"
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    "Please select a Region",
                                            },
                                        ]}>
                                        <Select disabled>
                                            <Option key="North" value="North">
                                                {"North"}
                                            </Option>
                                            <Option key="South" value="South">
                                                {"South"}
                                            </Option>
                                        </Select>
                                    </Form.Item>
                                )} */}

                                <Form.Item
                                    label="Division"
                                    name="Division"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please select a Division",
                                        },
                                    ]}>
                                    <AddEditDropdown
                                        selectDisabled={
                                            !user?.group_permissions?.includes(
                                                "add_factory"
                                            )
                                        }
                                        fieldValue={fieldValue}
                                        setFieldValue={setFieldValue}
                                        handleDropdown={handleDropdown}
                                        setHandleDropdown={setHandleDropdown}
                                        fields={divisionList}
                                        RenameField={RenameDivision}
                                        allowSpaces={false}
                                    />
                                </Form.Item>
                            </Col>
                            {user?.group_permissions?.includes(
                                "change_factory"
                            ) && (
                                <Col style={{ width: "50%" }}>
                                    <Form.Item
                                        label="Upload Master Data"
                                        name="csv_file"
                                        help={fileError} // This will display the error message
                                        validateStatus={
                                            fileError ? "error" : ""
                                        } // This changes the outline color to red if there's an error
                                    >
                                        <Dragger
                                            key={uploadKey}
                                            accept=".csv"
                                            maxCount={1}
                                            beforeUpload={(file) => {
                                                if (file.type !== "text/csv") {
                                                    setFileError(
                                                        "Please upload a correct format of Master Data File, required format is CSV"
                                                    );
                                                    return false;
                                                } else {
                                                    setFileError(null);
                                                    setFile(file);
                                                    return false; // prevent automatic upload
                                                }
                                            }}
                                            onChange={onFileChange}>
                                            <p className="ant-upload-drag-icon">
                                                <InboxOutlined />
                                            </p>
                                            <p className="ant-upload-text">
                                                Click or drag file to this area
                                                to upload
                                            </p>
                                            <p className="ant-upload-hint">
                                                Support for a single CSV file
                                                upload.
                                            </p>
                                        </Dragger>
                                    </Form.Item>
                                </Col>
                            )}
                        </Row>
                        <Row gutter={16} style={{ width: "100%" }}>
                            <Col style={{ width: "50%" }}>
                                <Form.Item
                                    label="Location"
                                    name="Location"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                "Please enter the Location",
                                        },
                                    ]}>
                                    <Input
                                        disabled={
                                            user.role === "SUPER_ADMIN" ||
                                            user.role === "REGIONAL_ADMIN"
                                                ? false
                                                : true
                                        }
                                        value={props?.Location}
                                    />
                                </Form.Item>
                            </Col>
                            {user?.group_permissions?.includes(
                                "change_factory"
                            ) && (
                                <Col
                                    style={{
                                        width: "50%",
                                    }}>
                                    <Button
                                        className={styles.outlineButton}
                                        onClick={() => {
                                            const url = `/assets/Doc/MasterDataTemplate.csv`;
                                            const link =
                                                document.createElement("a");
                                            link.href = url;
                                            link.download = "template.csv";
                                            document.body.appendChild(link);
                                            link.click();
                                            document.body.removeChild(link);
                                        }}>
                                        <DownloadOutlined />
                                        Download Sample Template
                                    </Button>
                                </Col>
                            )}
                        </Row>
                        <Row gutter={16} style={{ width: "100%" }}>
                            <Col style={{ width: "50%" }}>
                                <Form.Item
                                    label="Time Zone"
                                    name="Factory_timezone"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                "Please select a Time zone",
                                        },
                                    ]}>
                                    <AutoComplete
                                        disabled={!timeZones.length}
                                        placeholder="Enter Time zone"
                                        options={timeZoneOptions}
                                        onSearch={handleSearch}
                                        dropdownRender={(menu) => (
                                            <div className="autocomplete-dropdown">
                                                {menu}
                                            </div>
                                        )}
                                    />
                                </Form.Item>
                            </Col>
                            <Col style={{ width: "50%" }}>
                                <Form.Item
                                    label="Inache Number"
                                    name="Number"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                "Please select an Inache Number",
                                        },
                                    ]}>
                                    <Input
                                        value={props?.Number}
                                        disabled></Input>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </Modal>
        </>
    );
};

export default EditFactoryButton;
