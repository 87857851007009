import {
    Form,
    Layout,
    Row,
    Col,
    Select,
    Button,
    message,
    AutoComplete,
} from "antd";
import React, { useState, useRef } from "react";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
import {
    getFactoriesByRegion,
    setFactoryToRegion,
    createRegion,
    renameRegion,
    editTimezone,
} from "../../../../Adapters/RegionCalls";
import LoggedInComponent from "../../../../containers/LoggedInComponent/LoggedInComponent";
import CollapsableMenu from "../../../CollapsableMenu";
import { useRecoilState } from "recoil";
import { regionState } from "../../../../RecoilState/regionState";
import { FactoryData } from "../../../../RecoilState/FactoryData";
import styles from "../../AdminDashboard.module.css";
import RegionFactoryTable from "./RegionFactoryTable";
import useFetchRegion from "../RegionHooks/FetchRegionHook";
import ConfirmationModal from "./ConfirmationModal";
import { useFetchFactories } from "../../UserDashboard/UserAction/UserHooks/useFetchFactories";
import { EditRegionFooter, AddRegionFooter } from "./UpdateRegionFooter";
import ChangesConfirmationModal from "./ChangesConfirmationModal";
import useEventMetricsUpdater from "../../../../hooks/useEventMetricsUpdater";
import { userState } from "../../../../RecoilState/userState";
import { tokenState } from "../../../../RecoilState/tokenState";
import AddEditDropdown from "../../../Utils/AddEditDropdown";
import { fetchTimeZones } from "../../../../Adapters/FactoryAndCompanyCalls";
import {
    extractTimeZone,
    getFullTimeZoneFormat,
    handleTimeZoneSearch,
} from "../../../../utils/TimeZoneFunctions/TimeZoneFunctions";

const EditRegion = () => {
    const [form] = Form.useForm();
    const { state } = useLocation();
    const lodash = require("lodash");
    const [user] = useRecoilState(userState);
    const [token] = useRecoilState(tokenState);

    const navigate = useNavigate();
    const inputRef = useRef(null);

    const [regions] = useRecoilState(regionState);
    const [factories, setFactories] = useRecoilState(FactoryData);

    const [isLoading, setIsLoading] = useState(false);
    const [selectedFactory, setSelectedFactory] = useState([]);
    const [selectedFactoryName, setSelectedFactoryName] = useState([]);
    const [defaultFactory, setDefaultFactory] = useState([]);
    const [isOpenConfirmFactories, setIsOpenConfirmFactories] = useState(false);
    const [isOpenConfirmRegion, setIsOpenConfirmRegion] = useState(false);

    const { fetchRegionData, data, loading, error } = useFetchRegion();
    const { fetchFactoryData, Loading } = useFetchFactories();

    const { Option } = Select;
    const { updateEventMetrics } = useEventMetricsUpdater();
    const [regionmap] = useState(new Map());
    const [openCancelModal, setOpenCancelModal] = useState(false);
    const [regionDetail, setRegionDetail] = useState();
    const [handleDropdown, setHandleDropdown] = useState({
        open: false,
        edit: false,
        editId: null,
        disableInput: true,
        refreshRegion: true,
    });
    const [modalData, setModalData] = useState({
        eventName: "",
        actionType: "",
    });
    const [fieldValue, setFieldValue] = useState({
        id: state?.id,
        fieldrename: "",
        addField: "",
        newFieldName: "",
    });
    const [timeZoneOptions, setTimeZoneOptions] = useState([]);
    const [timeZones, setTimeZones] = useState([]);

    useEffect(() => {
        if (handleDropdown.refreshRegion && token) {
            fetchRegionData();
            setHandleDropdown((prev) => ({ ...prev, refreshRegion: false }));
        }
    }, [handleDropdown.refreshRegion, user?.role]);

    useEffect(() => {
        if (factories) {
            let factoryArray;
            factoryArray = factories.map((factory) => {
                return { ...factory, key: factory.id };
            });
            setFactories(factoryArray);
        }

        const fetchData = async () => {
            const data = await fetchTimeZones();
            setTimeZones(data);
        };

        fetchData();
    }, []);

    useEffect(() => {
        if (data) {
            data.forEach((value) => {
                regionmap.set(value.id, value.Name);
            });
        }
    }, [data]);

    useEffect(() => {
        const fetchData = async () => {
            if (fieldValue.id) {
                setIsLoading(true);

                try {
                    const { data } = await getFactoriesByRegion(fieldValue.id);

                    form.setFieldValue(
                        "Region_timezone",
                        getFullTimeZoneFormat(
                            timeZones,
                            data.message_body.Region_timezone
                        )
                    );

                    const regionFactory = lodash.uniqBy(
                        data.message_body.Factories,
                        "id"
                    );
                    setRegionDetail(data.message_body);
                    const Factory = [];
                    regionFactory.forEach((factory) => {
                        Factory.push(factory.id);
                    });
                    setSelectedFactory([]);
                    setDefaultFactory(Factory);
                } catch (error) {
                    if (error.response.status == 401) {
                        setHandleDropdown((prev) => ({
                            ...prev,
                            refreshRegion: true,
                        }));
                        fetchData();
                    } else {
                        console.error(
                            "Error fetching region factories :",
                            error
                        );
                        if (error.response && error.response.data) {
                            message.error(error.response.data.errorMessage);
                        }
                    }
                } finally {
                    setIsLoading(false);
                }
            } else {
                setSelectedFactory([]);
                setDefaultFactory([]);
            }
        };
        if (token) {
            fetchData();
        }
    }, [user?.role, fieldValue.id, timeZones]);

    useEffect(() => {
        form.setFieldsValue({ Region: fieldValue.newFieldName });
    }, [fieldValue, form]);

    const AddFactory = async () => {
        if (selectedFactory.length > 0) {
            let FilterFactories = factories.filter((factory) =>
                selectedFactory.includes(factory.id)
            );
            let result = FilterFactories.map((factory) => factory.Code);
            setSelectedFactoryName(result.join(", "));
            setIsOpenConfirmFactories(true);
        } else {
            // ConfirmModal(fieldValue.id);
            setIsOpenConfirmFactories(false);
            closeEditRegionScreen();
            const res = await editTimezone(
                fieldValue.id,
                extractTimeZone(form.getFieldValue("Region_timezone"))
            );
            message.success(res.data.message);
        }
    };

    const ConfirmModal = async (id) => {
        setIsOpenConfirmFactories(false);
        setIsLoading(true);
        try {
            const response = await setFactoryToRegion(id, selectedFactory);
            setHandleDropdown((prev) => ({ ...prev, refreshRegion: true }));
            fetchFactoryData();
            message.success(response.data.message);
            closeEditRegionScreen();
        } catch (error) {
            console.error("Error Updating Region Factories event:", error);
            if (error.response && error.response.data) {
                message.error(error.response.data.errorMessage);
            } else {
                message.error(
                    "An error occurred while updating region factories."
                );
            }
        } finally {
            setIsLoading(false);
        }
    };

    const closeEditRegionScreen = () => {
        navigate("/AdminTableView?key=1");
    };

    const AddRegionFactory = (eventName, actionType) => {
        setIsOpenConfirmRegion(true);
        setModalData({ eventName, actionType });
    };

    const ConfirmRegion = async () => {
        setIsOpenConfirmRegion(false);
        try {
            const response = await createRegion(
                fieldValue.newFieldName
                    ? fieldValue.newFieldName
                    : handleDropdown.newRegionName,
                extractTimeZone(form.getFieldValue("Region_timezone"))
            );
            setFieldValue({
                ...fieldValue,
                id: response.data.message_body.id,
                newRegionName: "",
            });
            setHandleDropdown((prev) => ({ ...prev, disableInput: true }));
            ConfirmModal(response.data.message_body.id);
            updateEventMetrics(modalData.eventName, modalData.actionType);
        } catch (error) {
            console.error("Error creating region:", error);
            if (error.response && error.response.data) {
                message.error(error.response.data.errorMessage);
                if (
                    error.response.data.errorMessage ===
                    `Region with Name ${handleDropdown.newRegionName} already exists`
                ) {
                    form.setFields([
                        {
                            name: "Region",
                            errors: ["Region Name already exists"],
                        },
                    ]);
                }
            } else {
                message.error("An error occurred while creating new region.");
            }
        }
    };

    const RenameRegion = async (id, name) => {
        try {
            const response = await renameRegion(id, name);
            regionmap.set(id, name);
            setHandleDropdown((prev) => ({ ...prev, refreshRegion: true }));
            message.success(response.data.message);
        } catch (error) {
            console.error("Error renaming region:", error);
            if (error.response && error.response.data) {
                message.error(error.response.data.errorMessage);
                if (
                    error.response.data.errorMessage ===
                    `Region with Name ${name} already exists`
                ) {
                    form.setFields([
                        {
                            name: "Region",
                            errors: ["Region Name already exists"],
                        },
                    ]);
                }
            } else {
                message.error("An error occurred while renaming region.");
            }
        }
    };

    const CloseModal = () => {
        if (handleDropdown.newRegionName || selectedFactory > 0) {
            setOpenCancelModal(true);
        } else {
            closeEditRegionScreen();
        }
    };
    const handleSearch = (value) => {
        handleTimeZoneSearch(timeZones, value, setTimeZoneOptions);
    };

    return (
        <Layout>
            <LoggedInComponent>
                <CollapsableMenu> </CollapsableMenu>
                <div className={styles.TableContainer}>
                    <div className={styles.header}>
                        <h4>
                            {/* back button to go back to the Manage Dashboard */}
                            <span>
                                <img
                                    type="link"
                                    style={{
                                        cursor: "pointer",
                                    }}
                                    src="/assets/images/back/Group 4495.svg"
                                    alt="back-logo"
                                    onClick={() => CloseModal()}
                                />
                            </span>
                            <span style={{ marginLeft: "20px" }}>
                                Add/Edit Region
                            </span>
                        </h4>
                    </div>
                    <div className={styles.divider}></div>
                    <Form
                        id="RegionEditForm"
                        name="RegionEditForm"
                        form={form}
                        onFinish={() => {
                            fieldValue.id
                                ? AddFactory()
                                : AddRegionFactory(
                                      "createRegion",
                                      "Create Region - Create Region"
                                  );
                        }}
                        onMouseLeave={() => {
                            setHandleDropdown((prev) => ({
                                ...prev,
                                open: false,
                                edit: false,
                            }));
                        }}
                        initialValues={{ region: fieldValue.id }}
                        className={isLoading ? ` ${styles.blur}` : null}>
                        <Row>
                            <Col
                                span={12}
                                style={{
                                    paddingTop: "30px",
                                    paddingLeft: "50px",
                                    paddingRight: "10px",
                                }}>
                                <Form.Item
                                    label="Region"
                                    name="Region"
                                    initialValue={fieldValue.id}
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please select a Region",
                                            transform: (value) => {
                                                if (!fieldValue.id) {
                                                    fieldValue.newFieldName &&
                                                        value.set(
                                                            fieldValue.newFieldName
                                                        );
                                                } else {
                                                    value.set(fieldValue.id);
                                                }
                                            },
                                        },
                                    ]}>
                                    <AddEditDropdown
                                        selectDisabled={false}
                                        fieldValue={fieldValue}
                                        setFieldValue={setFieldValue}
                                        handleDropdown={handleDropdown}
                                        setHandleDropdown={setHandleDropdown}
                                        form={form}
                                        fields={regions}
                                        RenameField={RenameRegion}
                                        includesPermission={user?.group_permissions?.includes(
                                            "change_factoryregion"
                                        )}
                                        allowSpaces={false}
                                    />
                                </Form.Item>
                            </Col>
                            <Col
                                span={12}
                                style={{
                                    paddingTop: "30px",
                                    paddingRight: "50px",
                                    paddingLeft: "10px",
                                }}>
                                <Form.Item
                                    label="Time Zone"
                                    name="Region_timezone"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                "Please select a Time zone",
                                        },
                                    ]}>
                                    <AutoComplete
                                        style={{
                                            width: 300,
                                        }}
                                        disabled={!timeZones.length}
                                        placeholder="Enter Time zone"
                                        options={timeZoneOptions}
                                        onSearch={handleSearch}
                                        dropdownRender={(menu) => (
                                            <div className="autocomplete-dropdown">
                                                {menu}
                                            </div>
                                        )}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Col style={{ padding: "30px" }}>
                            <Form.Item
                                label="Factories"
                                name="Factories"
                                style={{
                                    padding: "0px 20px",
                                    width: "400px",
                                }}></Form.Item>

                            <RegionFactoryTable
                                factories={factories}
                                id={fieldValue.id}
                                regionmap={regionmap}
                                defaultFactory={defaultFactory}
                                selectedFactory={selectedFactory}
                                setSelectedFactory={setSelectedFactory}
                            />
                        </Col>
                        <div className={styles.divider} />
                        {user?.group_permissions?.includes(
                            "change_factoryregion"
                        ) && (
                            <Row
                                justify="space-between"
                                style={{ padding: "10px 50px" }}>
                                <Col>
                                    <span style={{ color: "red" }}>*</span>{" "}
                                    means mandatory
                                </Col>
                                <Row justify="end" gutter={[16, 16]}>
                                    <Col>
                                        <Button
                                            type="secondary"
                                            className="secondaryButton"
                                            onClick={() => {
                                                CloseModal();
                                            }}
                                            style={{
                                                width: "100%",
                                                height: 40,
                                            }}>
                                            Cancel
                                        </Button>
                                    </Col>
                                    {fieldValue.id ? (
                                        <EditRegionFooter
                                            region={regionDetail}
                                            disable={
                                                defaultFactory.length > 0 ||
                                                selectedFactory.length > 0
                                            }
                                        />
                                    ) : (
                                        <AddRegionFooter />
                                    )}
                                </Row>
                            </Row>
                        )}
                    </Form>
                </div>
                <ChangesConfirmationModal
                    open={openCancelModal}
                    onCancel={() => setOpenCancelModal(false)}
                    onExit={() => closeEditRegionScreen()}
                />
                <ConfirmationModal
                    title="Confirm Transfer"
                    open={isOpenConfirmFactories}
                    onCancel={() => setIsOpenConfirmFactories(false)}
                    onClickConfirm={() => ConfirmModal(fieldValue.id)}
                    desription={`${selectedFactoryName} will be transferred to ${regionmap.get(
                        fieldValue.id
                    )} Region`}
                />
                <ConfirmationModal
                    title="Confirm"
                    open={isOpenConfirmRegion}
                    onCancel={() => {
                        setIsOpenConfirmRegion(false);
                    }}
                    onClickConfirm={() => {
                        ConfirmRegion();
                    }}
                    desription={`Are you sure to add "${
                        fieldValue.newFieldName
                            ? fieldValue.newFieldName
                            : handleDropdown.newRegionName
                    }" ?`}
                />
            </LoggedInComponent>
        </Layout>
    );
};

export default EditRegion;
