import React, { useRef, useState } from "react";
import { Row, Col, Select, Button, Input, Space } from "antd";

import { PlusCircleOutlined, EditOutlined } from "@ant-design/icons";
import { useRecoilState } from "recoil";
import { userState } from "../../RecoilState/userState";

let myTimeout;
const AddEditDropdown = ({
    fieldValue,
    setFieldValue,
    handleDropdown,
    setHandleDropdown,
    fields,
    RenameField,
    includesPermission,
    selectDisabled,
    allowSpaces = false,
}) => {
    const { Option } = Select;
    const inputRef = useRef(null);
    const [showEdit, setShowEdit] = useState(null);
    const editRef = useRef(null);
    const [user] = useRecoilState(userState);
    return (
        <Select
            disabled={selectDisabled}
            value={fieldValue.id}
            optionLabelProp="label"
            open={handleDropdown.open || handleDropdown.edit}
            placeholder={
                fieldValue.id ? (
                    ""
                ) : (
                    <label style={{ color: "rgb(24 22 22)" }}>
                        {fieldValue.newFieldName}
                    </label>
                )
            }
            onMouseEnter={() => clearTimeout(myTimeout)}
            onMouseDown={(e) => {
                setHandleDropdown((prev) => ({ ...prev, open: true }));
            }}
            onMouseLeave={() => {
                myTimeout = setTimeout(() => {
                    setHandleDropdown((prev) => ({
                        ...prev,
                        open: false,
                        edit: false,
                    }));
                }, 200);
            }}
            onChange={(value) => {
                if (editRef.current !== value) {
                    setHandleDropdown((prev) => ({
                        ...prev,
                        open: false,
                        edit: false,
                        disableInput: true,
                        editId: null,
                    }));
                    editRef.current = null;
                } else {
                    setHandleDropdown((prev) => ({
                        ...prev,
                        open: true,
                        disableInput: true,
                    }));
                }
                setFieldValue((prev) => ({
                    ...prev,
                    id: value,
                    newFieldName: "",
                }));
            }}
            dropdownRender={(menu) => (
                <>
                    {menu}
                    {includesPermission && (
                        <Space
                            style={{
                                backgroundColor: "#bfbfbf",
                                width: "100%",
                                padding: handleDropdown.disableInput
                                    ? "4px"
                                    : "0px",
                            }}
                            onClick={() => {
                                setHandleDropdown((prev) => ({
                                    open: false,
                                    edit: true,
                                    editId: null,
                                    disableInput: false,
                                }));
                                // setDisableInput(false);
                            }}>
                            <Button
                                style={{
                                    position: "sticky",
                                }}
                                type="text"
                                icon={<PlusCircleOutlined />}></Button>
                            {handleDropdown.disableInput ? (
                                <>Add New </>
                            ) : (
                                <Input
                                    placeholder="Add New"
                                    style={{
                                        background: "transparent",
                                        border: "none",
                                        width: "250px",
                                        textColor: "black",
                                    }}
                                    onClick={() =>
                                        setHandleDropdown((prev) => ({
                                            ...prev,
                                            open: true,
                                            edit: true,
                                        }))
                                    }
                                    ref={inputRef}
                                    value={fieldValue.addField}
                                    onChange={(event) => {
                                        const value = allowSpaces
                                            ? event.target.value
                                            : event.target.value.trim();
                                        setFieldValue((prev) => ({
                                            ...prev,
                                            addField: value,
                                        }));
                                    }}
                                    onPressEnter={() => {
                                        setHandleDropdown((prev) => ({
                                            ...prev,
                                            open: false,
                                            edit: false,
                                            disableInput: false,
                                        }));
                                        setFieldValue((prev) => ({
                                            ...prev,
                                            newFieldName: fieldValue.addField,
                                            id: null,
                                        }));
                                        //form.setFieldValue(null);
                                    }}
                                    disabled={handleDropdown.disableInput}
                                    onKeyDown={(e) => {
                                        e.stopPropagation();
                                    }}
                                />
                            )}
                        </Space>
                    )}
                </>
            )}>
            {fields &&
                fields.map((value, key) => (
                    <Option
                        data-testid={`option-${value.id}`}
                        key={value.id}
                        value={value.id}
                        label={value.Name}
                        onMouseEnter={() => {
                            if (
                                user?.group_permissions?.includes(
                                    "change_factoryregion"
                                )
                            ) {
                                setShowEdit(value.id);
                                clearTimeout(myTimeout);
                                setHandleDropdown((prev) => ({
                                    ...prev,
                                    open: true,
                                }));
                            }
                        }}
                        onMouseLeave={() => setShowEdit(null)}>
                        <Row
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                            }}>
                            {handleDropdown.editId == value.id ? (
                                <Input
                                    type="text"
                                    data-testid="edit-input"
                                    value={fieldValue.fieldrename}
                                    onClick={() => {
                                        inputRef.current.focus({
                                            cursor: "end",
                                        });
                                    }}
                                    onChange={(e) =>
                                        setFieldValue((prev) => ({
                                            ...prev,
                                            fieldrename: e.target.value.trim(),
                                        }))
                                    }
                                    ref={inputRef}
                                    onKeyDown={(e) => e.stopPropagation()}
                                    onPressEnter={() => {
                                        setHandleDropdown((prev) => ({
                                            ...prev,
                                            open: false,
                                            edit: false,
                                            editId: null,
                                        }));
                                        RenameField(
                                            value.id,
                                            fieldValue.fieldrename
                                        );
                                    }}
                                />
                            ) : (
                                <>
                                    <Col></Col>
                                    <Col>
                                        <label> {value.Name}</label>
                                    </Col>
                                </>
                            )}
                            <Col style={{ width: "25px" }}>
                                {showEdit == value.id &&
                                    handleDropdown.editId != value.id && (
                                        <Button
                                            data-testid="edit-button"
                                            icon={<EditOutlined />}
                                            type="text"
                                            onClick={() => {
                                                editRef.current = value.id;
                                                setHandleDropdown((prev) => ({
                                                    ...prev,
                                                    editId: value.id,
                                                    edit: true,
                                                }));
                                                setFieldValue((prev) => ({
                                                    ...prev,
                                                    fieldrename: value.Name,
                                                    addField: "",
                                                }));
                                            }}></Button>
                                    )}
                            </Col>
                        </Row>
                    </Option>
                ))}
        </Select>
    );
};

export default AddEditDropdown;
