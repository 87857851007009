import { common_axios } from "../utils/AxiosSettings";

export const getOfflineCasesData = async (url) => {
    const res = await common_axios.get(url);
    return res;
};

export const getOfflineCaseData = async (id) => {
    const res = await common_axios.get(
        `${process.env.REACT_APP_BASE_URL_API}/api/accounts/OfflineCase/${id}`
    );
    return res;
};

export const uploadOfflineCase = async (data) => {
    const res = await common_axios.post(
        `${process.env.REACT_APP_BASE_URL_API}/api/accounts/OfflineCase/`,
        data
    );
    return res;
};

export const addWorkerCommittee = async (data) => {
    const res = await common_axios.post(
        `${process.env.REACT_APP_BASE_URL_API}/api/accounts/WorkerCommittee/`,
        data
    );
    return res;
};
