/* Component with Casereport deisplay based on CR, CM and CT based reports */
/* Api request for preopulating the data */
/* Two basic forms - Case Details one with dropdown */
/* 2nd form is case closing report only for CT*/

import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Layout, message, Row, Tooltip } from "antd";
import { useRecoilState } from "recoil";

import CaseSendMessage from "../../components/CaseSendMessage";
import LoggedInComponent from "../../containers/LoggedInComponent/LoggedInComponent";
import ATTACHMENTcomponent from "./ATTACHMENTcomponent";
import GENERALcomponent from "./GENERALcomponent";
import PreviousTab from "./PreviousTab";
import SelectVersionFilter from "./PreviousTab/SelectVersionFilter";
import styles from "./CaseReport.module.css";
import CaseReport from "./OnlineCaseReport";
import { userState } from "../../RecoilState/userState";
import { getReopenTitle } from "../../hooks/useTableSchema";
import { getCounterFromCaseStatus } from "../../hooks/getCounterFromCaseStatus";
import { previousRouteData } from "../../RecoilState/previousRouteData";
import { caseReportDataState } from "../../RecoilState/caseReportDataState";
import { SendMessageVisibility } from "../../RecoilState/SendMessageVisibility";

const GeneralCaseReport = () => {
    const [user] = useRecoilState(userState);
    const [caseData] = useRecoilState(caseReportDataState);
    /*
    visible state decides to hide/show Send Message Modal
    */
    const [visible, setVisible] = useRecoilState(SendMessageVisibility);
    const [previousRoute] = useRecoilState(previousRouteData);
    const { state } = useLocation();
    const [selectedVersion, setSelectedVersion] = useState(null);
    /*
    Tab based State , Will render differect components 
    based on selectedTab State
    Values : GENERAL,INFO and ATTACHMENT
    */
    const [activeTab, setActiveTab] = useState("1");
    const from = state?.myData;
    const version = from?.Version;

    const items = [
        {
            key: "1",
            label: `General`,

            children: (
                <>
                    <GENERALcomponent
                        activeKey={activeTab}
                        caseData={from}
                        selectedVersion={selectedVersion}
                    />
                    <div className={styles.mandatoryBottom}>
                        <span style={{ color: "red" }}>*</span> means mandatory
                    </div>
                </>
            ),
        },
        {
            key: "2",
            label: `Attachments`,
            children: <ATTACHMENTcomponent caseData={caseData} />,
        },
        {
            key: "3",
            label: `Previous`,
            children: (
                <PreviousTab
                    selectedVersion={selectedVersion}
                    setSelectedVersion={setSelectedVersion}
                    version={version}
                />
            ),
            disabled: version <= 1,
        },
    ];

    const operations = {
        right: version > 1 && activeTab == "3" && selectedVersion !== null && (
            <div className={styles.VersionFilterWrapper}>
                <SelectVersionFilter
                    selectedVersion={selectedVersion}
                    setSelectedVersion={setSelectedVersion}
                    version={version}
                />
            </div>
        ),
    };

    return (
        <LoggedInComponent>
            <Layout
                style={{
                    padding: "1rem 2rem 1rem 2rem",
                    backgroundColor: "#F4F7FE",
                }}>
                <Row className={styles.caseReportRow}>
                    <div className={styles.headingContainer}>
                        <Link
                            to={
                                user.role === "SUPER_ADMIN" ||
                                user.role === "FACTORY_ADMIN" ||
                                user.role === "REGIONAL_ADMIN" ||
                                user.role === "LEAD_SUPERVISOR"
                                    ? previousRoute.defaultRoute
                                    : previousRoute.defaultRoute
                            }>
                            <img
                                src="/assets/images/back/Group 4495.svg"
                                alt="back-logo"
                            />
                        </Link>
                        <span className={styles.heading}>Case Report</span>
                        <div className={styles.reopened}>
                            {(caseData?.reopened === true ||
                                (caseData?.reopenType &&
                                    caseData?.reopenType !==
                                        "Not Reopened")) && (
                                <Tooltip
                                    title={() =>
                                        getReopenTitle(caseData?.reopenType)
                                    }>
                                    <span
                                        style={{
                                            color: "#E27A00",
                                            marginRight: "8px",
                                        }}>
                                        <img
                                            src="/assets/images/CaseTable/reopen.svg"
                                            alt="Reopened Case"
                                        />
                                    </span>
                                </Tooltip>
                            )}
                        </div>
                    </div>
                    <CaseSendMessage
                        caseData={from}
                        visible={visible}
                        makeItVisible={() => {
                            setVisible(true);
                        }}
                        hideModal={() => {
                            setVisible(false);
                        }}
                    />
                    <div className={styles.headerRight}>
                        {/* send message button is conditional on availability of phone number(from.Complainer)  */}
                        {from?.Complainer &&
                        ((user.role == "CM" &&
                            getCounterFromCaseStatus(from.CaseStatus) < 3) ||
                            (user.role == "CR" &&
                                getCounterFromCaseStatus(from.CaseStatus) <
                                    2) ||
                            ((user.role == "CT" ||
                                user.role === "REGIONAL_ADMIN") &&
                                getCounterFromCaseStatus(from.CaseStatus) <
                                    6)) ? (
                            <button
                                className={styles.sendMessage}
                                onClick={() => {
                                    setVisible(true);
                                }}>
                                Send Message
                            </button>
                        ) : null}

                        <Link
                            to={`/ViewLogs/${from?.id}`}
                            state={{ from: from }}
                            className={styles.viewLogs}>
                            View Logs
                        </Link>
                    </div>
                </Row>
                <Row className={styles.caseReportBody}>
                    <div style={{ width: "100%", height: "100%" }}>
                        <CaseReport
                            items={items}
                            operations={operations}
                            activeTab={activeTab}
                            setActiveTab={setActiveTab}
                        />
                    </div>
                </Row>
            </Layout>
        </LoggedInComponent>
    );
};

export default GeneralCaseReport;
