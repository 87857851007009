import React, { useState } from "react";
import { Button } from "antd";
import styles from "../../CaseReport.module.css";
import CaseReportTabItem from "../CaseReportTabItem/";
import TranscriptionModal from "../../../../components/TranscriptionComponent/TranscriptionModal";

const keysToIterate = [
    "CaseNumber",
    "Date",
    "Time",
    "CaseStatus",
    "ReportingMedium",
    "Version",
];
export default function CaseDetailsGeneral({ caseData, setAudioHeard }) {
    let caseDetailsRightDisplay = "";
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    if (caseData?.ReportingMedium === "Call") {
        caseDetailsRightDisplay = (
            <>
                <h4>Phone Call</h4>
                <div className={styles.audioContainer}>
                    <audio
                        onContextMenu={(e) => e.preventDefault()}
                        controls
                        onPlay={() => setAudioHeard(true)}
                        src={caseData.CallRecording_url}
                        controlsList="nodownload">
                        Your browser does not support the
                        <code>audio</code> element.
                    </audio>
                    <button
                        className="secondaryButton"
                        type="primary"
                        onClick={handleOpenModal}
                        style={{ marginTop: "10px" }}>
                        See Audio Transcription & Translation
                    </button>
                    <p style={{ marginTop: "10px" }}>
                        **We strongly suggest you to go through this
                    </p>
                </div>

                <TranscriptionModal
                    audioUrl={caseData.CallRecording_url}
                    caseId={caseData.id}
                    isOpen={isModalOpen}
                    onClose={handleCloseModal}
                />
            </>
        );
    } else if (caseData?.ReportingMedium === "SMS") {
        caseDetailsRightDisplay = (
            <>
                <h4>SMS</h4>
                <p>{caseData.SMS_Data}</p>
            </>
        );
    } else if (
        caseData?.ReportingMedium === "In Person" ||
        caseData?.ReportingMedium === "Suggestion Box" ||
        caseData?.ReportingMedium === "Worker Committee"
    ) {
        caseDetailsRightDisplay = (
            <div className={styles.singleItem}>
                <h4 style={{ paddingRight: "5%", width: "60%" }}>
                    Message from Worker:
                </h4>
                <p>{caseData.MessagebyWorker}</p>
            </div>
        );
    }

    return (
        <div className={styles.caseDetailsSection}>
            <div className={styles.caseDetailsLeft}>
                {keysToIterate.map((item, index) => {
                    return (
                        <CaseReportTabItem
                            tabValue={
                                item === "Time"
                                    ? caseData["Date"]
                                    : item === "CaseStatus"
                                    ? caseData[item] ===
                                      "Closed with Positive Response"
                                        ? "Positive Response"
                                        : caseData[item]
                                    : caseData[item]
                            }
                            tabName={item}
                            key={index}
                        />
                    );
                })}
            </div>
            <div className={styles.caseDetailsRight}>
                {caseDetailsRightDisplay}
            </div>
        </div>
    );
}
