import React, { useState } from "react";
import { Tabs } from "antd";

import styles from "./CaseReport.module.css";

const CaseReport = ({ labels, dataFields, data }) => {
    const [activeTab, setActiveTab] = useState("1");

    const handleTabChange = (key) => setActiveTab(key);

    //  A function that will render the labels based on their type
    const renderChild = (child) => {
        const value = data[child.dataIndex] || "";

        const getDisplayValue = (value, child) => {
            if (!value) {
                return "N/A";
            }

            if (child.name === "Committee Name") {
                return value[0];
            }

            if (child.dataIndex === "worker_name") {
                return "*******";
            }

            return value;
        };

        return (
            <div className={styles.labelPair} key={child.name}>
                {child.type === "label" ? (
                    <>
                        <label className={styles.label}>{child.name}</label>
                        <label className={styles.displayLabel}>
                            {getDisplayValue(value, child)}
                        </label>
                    </>
                ) : child.type === "text area" ? (
                    <>
                        <label className={styles.label}>{child.name}</label>
                        <label
                            readOnly
                            id="textArea"
                            className={styles.displayLabel}>
                            {value}
                        </label>
                    </>
                ) : child.type === "document" ? (
                    <>
                        <label className={styles.label}>{child.name}</label>
                        <div
                            style={{
                                width: "100%",
                                borderRadius: "3rem",
                                height: "2.5rem",
                                backgroundColor: "#E3E7F1",
                            }}>
                            {value}
                        </div>
                    </>
                ) : null}
            </div>
        );
    };

    // items array of objects, that is passed into the Tabs component
    const items = labels.map((label, index) => {
        const tabData = dataFields.find(
            (field) =>
                field.key === (index + 1).toString() &&
                data?.case_type === field.caseType
        );

        return {
            key: (index + 1).toString(),
            label: label,
            children: (
                <div className={styles.container}>
                    <div className={styles.leftColumn}>
                        {tabData?.fields
                            .filter((item) => item.position === "left") // render the labels pair to the left
                            .map(renderChild)}
                    </div>
                    <div className={styles.rightColumn}>
                        {tabData?.fields
                            .filter((item) => item.position === "right") // render the labels pair to the right
                            .map(renderChild)}
                    </div>
                </div>
            ),
        };
    });

    return (
        <div
            id="caseReportTabContainer"
            className={styles.caseReportTabContainer}>
            <Tabs
                onChange={handleTabChange}
                defaultActiveKey="1"
                activeKey={activeTab}
                items={items}
            />
        </div>
    );
};

export default CaseReport;
